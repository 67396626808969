import { useEffect } from "react";

const Updatecron = () => {

    useEffect(() => {
        
    const update = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/noticies/update', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                
            }
        })
            .then(response => response.json())
            .then((data) => {
          
            })
            .catch(error => {
                console.error('Error updating news:', error);

            });
    };
    update();
    }, []);

    return (
        <>
        </>
    )
}
export default Updatecron;
