import ResponsiveAppBar from './menu';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Dashboard from './dashboard';
import Login from './login';
import Notificacions from './notificacions';
import Publicitat from './publicitat';
import Updatecron from './updateCron';

const App = () => {

  const location = useLocation();

  return (
    <div className="App">
      <ResponsiveAppBar />
      <Routes key={location.pathname}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Dashboard />} />
      <Route path="/notificacions" element={<Notificacions />} />
      <Route path="/Espai publicitari" element={<Publicitat />} />
      <Route path="/updatecron" element={<Updatecron />} />

      </Routes>
    </div>
  );
}

export default App;
